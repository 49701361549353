import { Avatar, Box, Divider, Stack, Typography } from '@mui/material';
import closeIcon from 'images/close.svg';
import {
  MenuItem,
  MenuItemCustomization,
  MenuItemCustomizationOption,
} from 'interfaces/MenuInterface';
import { useDietaryPreferences } from 'providers/DietaryPreferenceProvider';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PrimaryActionButton } from '../ExploreRestaurant/components/PrimaryActionButton';
import { useCart } from '../ExploreRestaurant/context/CartContext';
import { formatCentsToDollars, getEnabledMatchingCustomizations as getMatchingCustomizations } from '../ExploreRestaurant/util/util';
import MenuItemCustomizationView from './MenuItemCustomizationView';
import GroupedButtons from './components/GroupedButtons';

interface Props {
  menuItem: MenuItem;
  selectedCustomizationOptionIds: string[];
  setSelectedCustomizationOptionIds: React.Dispatch<React.SetStateAction<string[]>>
  filteredCustomizations: MenuItemCustomization[];
  handleAddItemToOrder: (price: number, quantity: number) => void;
  onClose: () => void;
  setCurrentPrice: React.Dispatch<React.SetStateAction<number>>;
}

const CustomDivider = () => {
  return <Divider sx={{ border: '3px solid #E8E8E8' }} />;
}

export const MenuItemDetail = ({
  menuItem,
  onClose,
  selectedCustomizationOptionIds,
  setSelectedCustomizationOptionIds,
  filteredCustomizations = [],
  handleAddItemToOrder,
  setCurrentPrice,
}: Props) => {
  const { price, disabled } = menuItem;
  const navigate = useNavigate();
  const { restaurantId } = useParams();
  const closeIconProperties = {
    height: '30px',
    width: '30px',
    position: "absolute",
    top: '10px',
    left: '10px',
    cursor: 'pointer'
  };

  const { isInCart, removeFromCart } = useCart();
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [updatedPrice, setUpdatedPrice] = useState(price);
  const { enabledAllergies, enabledDiets } = useDietaryPreferences();
  const [individualSelectedCustomizationOptionIds, setIndividualSelectedCustomizationOptionIds] = useState<any>({});
  const [individualErrors, setIndividualErrors] = useState<any>({});

  const customizationPrice = filteredCustomizations
    .flatMap(({ menuItemCustomizationOptions }) => getMatchingCustomizations(menuItemCustomizationOptions, {
      enabledAllergies,
      enabledDiets,
    }))
    .filter(({ id }) => selectedCustomizationOptionIds.includes(id ?? ''))
    .reduce((sum, { price }) => {
      return sum + price;
    }, 0);

  const setSelectedQuantityHandler = (val: number) => {
    setSelectedQuantity(val);
    const updatedPrice = (price + customizationPrice) * val;
    setUpdatedPrice(updatedPrice);
  }

  const setIndividualSelectedCustomizationOptionIdsHandler = (key: string, value: any) => {
    const tempSelections: any = individualSelectedCustomizationOptionIds;
    if (!tempSelections[key as any]) {
      tempSelections[key as any] = [];
    }
    tempSelections[key as any].push(value);
    setIndividualSelectedCustomizationOptionIds(tempSelections);
  }

  useEffect(() => {
    const updatedPrice = (price + customizationPrice) * selectedQuantity;
    setUpdatedPrice(updatedPrice);
  }, [selectedCustomizationOptionIds]);

  const handleCustomizationToggle = (option: MenuItemCustomizationOption) => {
    if (disabled) return; // Don't allow toggling if the item is disabled

    const customizationOptionIds = [...selectedCustomizationOptionIds];

    const index = selectedCustomizationOptionIds.indexOf(option.id!);

    if (index == -1) {
      customizationOptionIds.push(option.id!);
    } else {
      customizationOptionIds.splice(index, 1);
    }

    setSelectedCustomizationOptionIds(customizationOptionIds);
  };

  const handleAddItemToOrderHandler = () => {
    if (disabled) return; // Prevent adding to the cart if the item is disabled

    const tempErrors: any = {};
    let error = false;
    filteredCustomizations.map((customization) => {
      const key = customization.id;
      if (key) {
        if (individualSelectedCustomizationOptionIds[key] && customization.minPermitted > 0) {
          if (individualSelectedCustomizationOptionIds[key][individualSelectedCustomizationOptionIds[key].length - 1].length < customization.minPermitted) {
            error = true;
            if (!tempErrors[key as any]) {
              tempErrors[key as any] = [];
            }
            tempErrors[key as any].push("Minimum " . concat(customization.minPermitted.toString(), " selection(s) required!"));
          }
        } else if (individualSelectedCustomizationOptionIds[key] && customization.maxPermitted > 0) {
          if (individualSelectedCustomizationOptionIds[key][individualSelectedCustomizationOptionIds[key].length - 1].length > customization.maxPermitted) {
            error = true;
            if (!tempErrors[key as any]) {
              tempErrors[key as any] = [];
            }
            tempErrors[key as any].push("Maximum " . concat(customization.maxPermitted.toString(), " selection(s) required!"));
          }
        }
      }
    });

    setIndividualErrors(tempErrors);
    if (!error) {
      handleAddItemToOrder(price, selectedQuantity);
      onClose();
    }
  }

  return (
    <Stack>
      <Avatar src={closeIcon} sx={closeIconProperties} onClick={() => onClose()} />
      <CustomDivider />
      <Box sx={{ mx: 3, my: 2 }}>
        <Typography variant="h3" color={disabled ? 'gray' : 'black'}>{menuItem.title}</Typography>
        <Typography variant="subtitle1" color={disabled ? 'gray' : 'black'}>{menuItem.description}</Typography>
      </Box>
      <CustomDivider />
      <Box sx={{ mx: 3, mt: 2 }}>
        {filteredCustomizations.map((customization) => (
          <MenuItemCustomizationView
            key={customization.id!}
            selectedOnly={false}
            customization={customization}
            setIndividualSelectedCustomizationOptionIds={(key, value) => setIndividualSelectedCustomizationOptionIdsHandler(key, value)}
            individualSelectedCustomizationOptionIds={individualSelectedCustomizationOptionIds}
            onToggle={handleCustomizationToggle}
            individualErrors={individualErrors[customization.id!]}
            selectedCustomizationOptionIds={selectedCustomizationOptionIds}
            disabled={disabled} // Pass the disabled state to disable customizations
          />
        ))}
      </Box>
      <CustomDivider />
      <Box sx={{ mx: 3, my: 2, textAlign: 'center' }}>
        <GroupedButtons setSelectedQuantity={(val: number) => setSelectedQuantityHandler(val)} disabled={disabled} />
      </Box>
      <Divider />
      <Box sx={{ mx: 3, my: 2, textAlign: 'center' }}>
        <PrimaryActionButton title={'Add to Order ('.concat(formatCentsToDollars(updatedPrice), ')')} onClick={handleAddItemToOrderHandler} disabled={disabled} />
      </Box>
    </Stack>
  );
};
